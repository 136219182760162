<template>
  <div id="app">
    <infosys/>
  </div>
</template>

<script>
import infosys from './components/infosys.vue'

export default {
  name: 'App',
  components: {
    infosys
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  display: table;
  position: relative;
  background-image: url(./assets/schiffe.jpg);
  background-size: 100%;
}
</style>
