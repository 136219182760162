<template>
  <transition name="fade">
    <div id="infosys" v-if="show">
      <div>
        <div id="infosys_header">
          <img src="../assets/logo.jpg" />
        </div>
        <div id="infosys_body">
          <div id="title">{{ entrie.titel }}</div>
          <div id="inhalt_left" v-html="entrie.inhalt_left"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "infosys",
  data() {
    return {
      show: false,
      entrie: [],
      lines: "",
      idList: [],
      index: 0,
      idinfosys: "0",
      API_ENDPOINT: process.env.VUE_APP_HANDLER_API_ENDPOINT,
    };
  },
  mounted: function () {
    this.reload();
  },
  updated: function () {
    this.scaleFont();
  },
  methods: {
    scaleFont: function () {
      let inhalt_left = document.querySelector("#inhalt_left");
      this.lines = inhalt_left.childElementCount;
      switch (this.lines) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          inhalt_left.classList.add("font100");
          break;
        case 6:
          inhalt_left.classList.add("font90");
          break;
        case 7:
          inhalt_left.classList.add("font90");
          break;
        case 8:
          inhalt_left.classList.add("font80");
          break;
        case 9:
          inhalt_left.classList.add("font70");
          break;
        case 10:
          inhalt_left.classList.add("font70");
          break;
      }
    },
    checkInfosysId: function () {

      if(localStorage.data == undefined){
        this.reload();
      }

      let data = JSON.parse(localStorage.data);
      this.idList = [];

      //console.log("data: "+ data);
      //console.log("idinfosys: " +this.idinfosys);
      //console.log("index: "+this.index);

      data.forEach((element) => {
        this.idList.push(element.idinfosys);
      });

      if (this.idinfosys == 0 || this.idinfosys == undefined) {
        this.idinfosys = this.idList[this.index];
        this.index++;
      } else {
        if (this.idinfosys == this.idList[this.idList.length - 1]) {
          this.index = 0;
          this.idinfosys = this.idList[this.index];
          this.index++;
        } else {
          this.idinfosys = this.idList[this.index];
          this.index++;
        }
      }

      data.forEach((object) => {
        if (object.idinfosys == this.idinfosys) {
          this.entrie = object;
        }
      });
    },
    reload: function () {
      this.show = false;
      setTimeout(() => {
        Vue.axios
          .get(this.API_ENDPOINT)
          .then((response) => {
            if(response.data.arrayElements.length == 0){
              //console.log("vacio");
              this.index = 0;
              this.idinfosys = 0;
              this.entrie = [];
            }else{
              if(this.idinfosys == undefined){
                this.index = 0;
                this.idinfosys = 0;
                this.entrie = [];               
              }
              //console.log(response.data.arrayElements);
              localStorage.data = JSON.stringify(response.data.arrayElements);
              this.checkInfosysId();
              this.show = true;
            }
          })
          .catch(function (error) {
            console.warn(error);
            console.warn("There is not internet connection!!");
          });      

        setTimeout(() => {
          this.reload();
        }, 5000);
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#infosys {
  width: 95%;
  height: 90%;
  margin: auto;
  margin-top: 2.5%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  border-left: 10px solid #49c4e8;
  overflow: hidden;
}

#infosys_header {
  width: 100%;
  height: auto;
  background-color: #fff;
}

#infosys_header > img {
  margin: 0.5rem;
}

#infosys_body {
  width: 100%;
  position: absolute;
  height: 80vh;
}

#title {
  padding-top: 35px;
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

#inhalt_left {
  padding-top: 35px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 60px;
  color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.font100 {
  font-size: 5.9vw !important;
}
.font90 {
  font-size: 4.7vw !important;
}
.font80 {
  font-size: 3.9vw !important;
}
.font70 {
  font-size: 3.7vw !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
